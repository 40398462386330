<template>
    <v-dialog scrollable :width="libType == 'SEGMENT' || libType == 'EXPORT' ? 800 : 1200" v-model="openDialog">
        <v-card color="lightgrey">
            <v-toolbar flat class="bg-gradient" height="64">
                <v-btn x-large depressed style="position:absolute;" color="transparent" class="ml-2" dark @click.native="openDialog =false" ><v-icon small class="mr-2">$prev</v-icon> {{ $t("back") }}</v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title>
                    <h3 class="white--text" v-if="libType != 'COACH'">{{$t('modelPreview')}}</h3>
                    <h3 class="white--text" v-else>{{$t('coachPreview')}}</h3>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="showUseModelButton && libType != 'COACH'" x-large outlined depressed style="position:absolute; right: 0;" class="mr-6" dark @click="openDialog = false; $emit('selectModel', model)">{{ $t("useModel") }}</v-btn>
                <v-btn v-if="showUseModelButton && libType == 'COACH'" x-large outlined depressed style="position:absolute; right: 0;" class="mr-6" dark @click="openDialog = false; $emit('selectModel', model)">{{ $t("selectCoach") }}</v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row class="mt-2" v-if="previewObj && previewObj.hasOwnProperty('created_at')">
                    <v-col cols="12" v-if="libType == 'LANDINGPAGE'">                        
                        <landingPagePreview :previewObj="previewObj" :landing="{currentLanding : previewObj.settings, css: previewObj.styleConf}" :key="'lp_'+componentKey"/>
                    </v-col>
                    <v-col cols="12" v-if="libType == 'LANDINGPAGEV2'" class="contentbuildercontainer preview" :key="'lp2_'+componentKey">      
                        <landingPagePreviewV2 v-if="openPreviewDialog" :previewObj="previewObj" />
                    </v-col>
                    <v-col cols="12" v-if="libType == 'FORM'" :key="'form_' + componentKey">
                        <component is="script" :src="api + '/views/survey.js'" class="survey-script" :data-id="previewObj.id"></component>
                    </v-col>
                    <v-col cols="12" v-else-if="libType == 'WORKFLOW'">
                        <div class="mb-6">
                            <div class="d-flex align-center mb-1">
                                <v-badge class="mt-1 ml-2 mr-6" :color="previewObj.draft == 1 ? 'grey' : 'success'" dot></v-badge>
                                <h2 class="mb-0" style="font-size:24px; line-height:24px;">{{ previewObj.name }}</h2>
                            </div>
                            <p class="mb-1" v-html="previewObj.description"></p>
                        </div>
                        <div style="height:600px; position:relative;">
                            <WorkFlowComponent :workflow="previewObj" :preview="true" :stats="previewObj.stats" :config="previewObj.config"  :key="'wf_'+componentKey"/>
                        </div>
                    </v-col>
                    <v-col cols="12" v-else-if="libType == 'SEGMENT'">
                        <SegmentPreview :previewObj="previewObj" :realTime="previewObj.realTime" />
                    </v-col>
                    <v-col cols="12" v-else-if="libType == 'EXPORT'">
                        <div class="mb-6">
                            <div class="d-flex align-center mb-1">
                                <v-badge class="mt-1 ml-2 mr-6" :color="previewObj.draft == 1 ? 'grey' : 'success'" dot></v-badge>
                                <h2 class="mb-0" style="font-size:24px; line-height:24px;">{{ previewObj.name }}</h2>
                            </div>
                            <p class="mb-1" v-html="previewObj.description"></p>
                        </div>
                        <p class="mb-2 black--text" style="font-size:16px;">{{ $t('columnnsInExport') }} :</p>
                        <ul>
                            <li v-for="(column, index) in previewObj.columns" :key="index">
                                {{column.text}}
                            </li>
                        </ul>
                    </v-col>
                    <v-col cols="12" v-else-if="libType == 'OPERATIONTYPE'">
                        <OpeModelPreview :previewObj="previewObj"  :key="'ope_'+componentKey" />
                    </v-col>
                    <v-col cols="12" v-else-if="libType == 'COACH'">
                        <v-row>
                            <v-col cols="5">
                                <coachIdComponent :user="previewObj" :isPreview="true"/>
                            </v-col>
                            <v-col cols="7">
                                <coachRatingOpinionComponent :user="previewObj" />
                                <coachMediaComponent class="mt-6" :user="previewObj" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" v-else >
                        <div class="mb-6">
                            <div class="d-flex align-center mb-1">
                                <v-badge class="mt-1 ml-2 mr-6" :color="previewObj.draft == 1 ? 'grey' : 'success'" dot></v-badge>
                                <h2 class="mb-0" style="font-size:24px; line-height:24px;">{{ previewObj.name }}</h2>
                            </div>
                            <p class="mb-1" v-html="previewObj.description"></p>
                        </div>
                        <templateDetailComponent ref="templatePreview" :key="detailKey" :operationId="operation_id" :campaign="campaign" :fromStepper="fromStepper" :inStepper="fromStepper ? true : false" :template="previewObj" :inTemplatePage="inCampaign ? false : true" :inWorkflow="inCampaign ? false : true" :inCampaignTemplatePage="inCampaign" @customRefresh="customRefresh" @refreshTemplate="customRefresh" @addEntryPoint="addEntryPoint"/>
                    </v-col>
                    <v-col cols="12" v-if="libType != 'COACH'">
                       {{ $t('createdBy') }} : {{ previewObj.user != "" ? previewObj.user : $t('notSetted') }} {{ $t('the') }} {{ parseFullDateToFr(previewObj.created_at) }}<br>
                       {{ $t('reference') }} : {{ previewObj.reference }}<br>
                       <span v-for="(filter, index) in previewObj.filters">
                            {{ $t('FIELDFilters' + index) }} :
                            <span v-if="Array.isArray(filter)"><span v-for="(filterObj, index2) in filter">{{ filterObj.text ? (filterObj.to_trad ? $t(filterObj.text) : filterObj.text) : filterObj }}<span v-if="filter.length > 1 && index2 < (filter.length - 1)">, </span></span></span>
                            <span v-else>{{ filter.text ? (filter.to_trad ? $t(filter.text) : filter.text) : filter }}</span>
                            <br>
                       </span>
                    </v-col>
                </v-row>
            </v-card-text>
            <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="'LANDINGPAGE'" @cancel="formLib = null;" @customRefresh="customRefresh" @selectModel="" :libParams="{operation_id : $route.params.operation_id}" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" />
            <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
        </v-card>
    </v-dialog>
</template>
<script>
import GenericDataService from '@/services/GenericDataService';
import landingPagePreview from './landingPagePreview.vue';
import landingPagePreviewV2 from './landingPagePreviewV2.vue';
import dateManipulation from '@/mixins/dateManipulation'
import templateDetailComponent from "@/components/templates/templateDetailComponent";
import WorkFlowComponent from '@/modules/workflow/views/WorkFlowComponent'
import SegmentPreview from './SegmentPreview.vue';
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import OpeModelPreview from './OpeModelPreview.vue';
import coachIdComponent from '../modules/users/usersComponents/coachIdComponent.vue';
import coachRatingOpinionComponent from '../modules/users/usersComponents/coachRatingOpinionComponent.vue';
import coachMediaComponent from '../modules/users/usersComponents/coachMediaComponent.vue';

import getForm from "@/mixins/mixins";

export default {
    name: "LibraryPreviewComponent",
    props:['openPreviewDialog', 'model', 'module', 'libType', 'inCampaign', 'campaign', 'showUseModelButton', 'fromStepper', 'operation_id', 'fromPresta'],
    components: {
        coachIdComponent, landingPagePreview, FormDialogComponent, templateDetailComponent, WorkFlowComponent, SegmentPreview, OpeModelPreview,coachRatingOpinionComponent, coachMediaComponent,
        LibraryDialogComponent: () => import("@/components/dialogs/LibraryDialogComponent"),landingPagePreviewV2
    },
    mixins:[dateManipulation, getForm],
    data() {
        return {
            api: this.$store.state.auth.apiUrl,
            previewObj: {},
            detailKey: 0,
            formLib:null,
            showLibraryDialog:false,
            formType: null,
            formUrl: null,
            form: null,
            componentKey:0
        }
    },
    created() {
        if(this.model && !this.inCampaign)
            GenericDataService.getData('library/getPreview?id=' + (this.model.template_id ? this.model.template_id : this.model.id) + '&libType='+this.libType).then((response) => {
                this.previewObj = response.data.data
            })
        else {
            if(this.fromStepper){
                let campaignId = this.campaign.retroplanning_id ? this.campaign.retroplanning_id : this.campaign.id;
                GenericDataService.getData('/operation/' + this.operation_id + '/getCompiledTemplate?opObject_id=' + campaignId + '&tpl_id=' + (this.model.template_id ? this.model.template_id : this.model.id)).then((response) => {
                    if(response.data.data){
                        this.previewObj = response.data.data;
                        this.loader = false;
                    }
                });
            } else {
                let url = '/retroplanning/' + this.campaign.id + '/getCompiledTemplate?id=' + (this.model.template_id ? this.model.template_id : this.model.id);
                if(this.fromPresta)
                    url = '/mission/getCompiledTemplate?id=' + this.campaign.id + '&template_id=' + (this.model.template_id ? this.model.template_id : this.model.id);

                GenericDataService.getData(url).then((response) => {
                    if(response.data.data){
                        this.previewObj = response.data.data;
                        this.loader = false;
                    }
                });
            }
        }           
    },
    watch: {
        openPreviewDialog(val){
            if(val && !this.inCampaign)
                GenericDataService.getData('library/getPreview?id=' + (this.model.template_id ? this.model.template_id : this.model.id) + '&libType='+this.libType).then((response) => {
                    this.previewObj = response.data.data
                })
            else if(val) {
                if(this.fromStepper){
                    let campaignId = this.campaign.retroplanning_id ? this.campaign.retroplanning_id : this.campaign.id;
                    GenericDataService.getData('/operation/' + this.operation_id + '/getCompiledTemplate?opObject_id=' + campaignId + '&tpl_id=' + (this.model.template_id ? this.model.template_id : this.model.id)).then((response) => {
                        if(response.data.data){
                            this.previewObj = response.data.data;
                            this.loader = false;
                        }
                    });
                } else {
                    let url = '/retroplanning/' + this.campaign.id + '/getCompiledTemplate?id=' + (this.model.template_id ? this.model.template_id : this.model.id);
                    if(this.fromPresta)
                        url = '/mission/getCompiledTemplate?id=' + this.campaign.id + '&template_id=' + (this.model.template_id ? this.model.template_id : this.model.id);

                    GenericDataService.getData(url).then((response) => {
                        if(response.data.data){
                            this.previewObj = response.data.data;
                            this.loader = false;
                        }
                    });
                }
            }
        },
        previewObj(val){
            this.componentKey++
        }
    },
    computed: {
        openDialog: {
            // getter
            get() {
                return this.openPreviewDialog
            },
            // setter
            set(newValue) {
                if(!newValue && this.libType == 'VMS'){
                    this.$refs.templatePreview.stopPreview()
                }
                this.$emit('close', newValue)
            }
        }
    },
    methods: {
        addEntryPoint($evt, type){
            if(type == 'entryLanding'){
                GenericDataService.getData("/library/getFilters?libType=LANDINGPAGE").then((response) => {
                    this.formLib = response.data.data;
                    this.showLibraryDialog = true;
                    //this.libType = btn.openLibraryDialog;
                    //this.$emit("hideGlobalAddDialog");
                });
            } else {
                this.formUrl = $evt;
                this.getForm($evt);
            }
        },
        customRefresh(){
            if(!this.inCampaign) {
                GenericDataService.getData('library/getPreview?id=' + (this.model.template_id ? this.model.template_id : this.model.id) + '&libType='+this.libType).then((response) => {
                    this.previewObj = response.data.data
                    this.detailKey++;
                })
            } else if(this.fromStepper) {
                let campaignId = this.campaign.retroplanning_id ? this.campaign.retroplanning_id : this.campaign.id;
                GenericDataService.getData('/operation/' + this.operation_id + '/getCompiledTemplate?opObject_id=' + campaignId + '&tpl_id=' + (this.model.template_id ? this.model.template_id : this.model.id)).then((response) => {
                    if(response.data.data){
                        this.previewObj = response.data.data;
                        this.detailKey++;
                    }
                });
            } else {
                GenericDataService.getData('/retroplanning/' + this.campaign.id + '/getCompiledTemplate?id=' + (this.model.template_id ? this.model.template_id : this.model.id)).then((response) => {
                    if(response.data.data){
                        this.previewObj = response.data.data;
                        this.detailKey++;
                    }
                });
            }  
            this.$emit('refreshTemplate');
        }
    },
}
</script>

<style lang="scss">
    #surveyElement-0 {
        input[type=button] {
            pointer-events: none;
        }
    }
</style>